<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Playbook <span>Resend Login Details</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="section_wpr mb-2">
                    <div class="edit_section">
                        <sending-method v-model="form.sending_method" />
                    </div>
                </div>
                <div v-if="form.sending_method == 1 || form.sending_method == 3">
                    <div class="capsule_elm">
                        <h5>Email Setup</h5>
                        <label for="email_alert" class="switch_option capsule_btn">
                            <input type="checkbox" id="email_alert" v-model="form.email_enabled" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="setting_wpr mb-4" v-if="form.email_enabled">
                        <email-component v-model="emailContent" ref="resend-email-component" :errors="{}" preview-module="playbook" :handle-default-email="handleDefaultEmail" />
                    </div>
                </div>
                <div v-if="form.sending_method == 2 || form.sending_method == 3">
                    <div class="capsule_elm">
                        <h5>SMS Setup</h5>
                        <label for="sms_alert" class="switch_option capsule_btn">
                            <input type="checkbox" id="sms_alert" v-model="form.sms_enabled" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="setting_wpr" v-if="form.sms_enabled">
                      <sms-component v-model="form.sms" preview-module="playbook" ref="resend-sms-component" />
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="playbookResendLoader" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                <button :disabled="playbookResendLoader" class="btn save_btn" @click="handleResendPlaybook"> <i class="fa fa-spin fa-spinner" v-if="playbookResendLoader"></i> {{ playbookResendLoader ? 'Sending' : 'Send' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const SendingMethod = defineAsyncComponent(() => import('@/components/SendingMethod'))
    const EmailComponent = defineAsyncComponent(() => import('@/components/EmailComponent'))
    const SmsComponent = defineAsyncComponent(() => import('@/components/SmsComponent'))

    export default {
        name: 'Playbook Resend Login Details',

        data () {
            return {
                form: {
                    sending_method: 1,
                    playbook_id: 0,
                    contact_id: 0,
                    sms: '',
                    email_subject: '',
                    email_content: '',
                    reset_image: '',
                    is_reset_logo: '',
                    reset_show_email_signature: 0,
                    reset_email_signature: 0,
                    pass_sms_media: [],
                    email_enabled: 0,
                    sms_enabled: 0,
                },
                emailContent: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                playbookResendLoader: false,
            };
        },

        props: {
            modelValue: Boolean,
            playbook: Object,
            contactId: Number,
        },

        emits: ['update:modelValue'],

        components: {
            SendingMethod,
            EmailComponent,
            SmsComponent,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();
                }
            }
        },

        methods: {
            ...mapActions({
                resendPlaybook: 'playbookModule/resendPlaybook',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    sending_method: 1,
                    playbook_id: vm.playbook.id,
                    contact_id: vm.contactId,
                    sms: vm.playbook.password_message.sms_message,
                    email_subject: vm.playbook.password_message.email_subject,
                    email_content: vm.playbook.password_message.email_message,
                    reset_image: vm.playbook.reset_email_logo,
                    is_reset_logo: vm.playbook.reset_show_email_logo,
                    reset_show_email_signature: vm.playbook.reset_show_email_signature,
                    reset_email_signature: vm.playbook.reset_email_signature,
                    pass_sms_media: vm.playbook.password_message.media ? vm.playbook.password_message.media : [],
                    email_enabled: 0,
                    sms_enabled: 0,
                };

                vm.emailContent = {
                    defaultHandler: true,
                    subject: vm.playbook.password_message.email_subject,
                    email: vm.playbook.password_message.email_message,
                    is_email_logo: vm.playbook.reset_show_email_logo,
                    is_signature: vm.playbook.reset_show_email_signature,
                    signature_id: vm.playbook.reset_email_signature,
                    show_email_logo: vm.playbook.reset_show_email_logo,
                    email_logo: vm.playbook.reset_email_logo,
                    module_id: vm.playbook.id,
                };
            },

            handleDefaultEmail () {
                const vm = this;

                vm.emailContent = {
                    defaultHandler: true,
                    defaultEmail: true,
                    subject: vm.playbook.password_message.email_subject,
                    email: vm.playbook.password_message.email_message,
                    is_email_logo: vm.playbook.reset_show_email_logo,
                    is_signature: vm.playbook.reset_show_email_signature,
                    signature_id: vm.playbook.reset_email_signature,
                    show_email_logo: vm.playbook.reset_show_email_logo,
                    email_logo: vm.playbook.reset_email_logo,
                };
            },

            handleResendPlaybook () {
                const vm = this;

                const params = vm.form;
                params.email_subject              = vm.emailContent.subject;
                params.email_content              = vm.emailContent.email;
                params.reset_image                = vm.emailContent.email_logo;
                params.is_reset_logo              = vm.emailContent.show_email_logo;
                params.reset_show_email_signature = vm.emailContent.is_signature;
                params.reset_email_signature      = vm.emailContent.signature_id;

                vm.playbookResendLoader = true;

                vm.resendPlaybook(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }

                    vm.playbookResendLoader = false;
                });
            },
        },
    }
</script>

